import { useParams } from "next/navigation"

import { useAlertContext } from "@unlikelystudio/react-abstract-components"
import { formDictionary } from "@unlikelystudio/react-ecommerce-hooks"

import { ALERT_CODES, type AlertPresetValues, type FormDictionnaryKey, type FormDictionnaryLang } from "~/lib/alert"
import { getI18nKey } from "~/lib/i18n/utils/get-i18n"
import { getProcessedLocale } from "~/lib/i18n/utils/get-processed-locale"
import { alertKeys } from "~/providers/I18nProvider/constants"
import { useTranslate } from "~/providers/I18nProvider/hooks/useTranslate"

export const useAlerts = () => {
  const { triggerAlert } = useAlertContext()
  const t = useTranslate()

  const params = useParams()
  const language = getI18nKey(getProcessedLocale(params.locale), "lang")

  const getPreset = (preset: AlertPresetValues = ALERT_CODES.SERVER_ERROR) => {
    switch (preset) {
      case ALERT_CODES.ENTITY_SAVED:
      case ALERT_CODES.NEWSLETTER_SUBSCRIBE:
      case ALERT_CODES.RESET_PASSWORD_EMAIL_SENT:
      case ALERT_CODES.EMAIL_SENT:
      case ALERT_CODES.LOGGED_IN:
      case ALERT_CODES.FORM_SUCCESS:
        return {
          title: t(alertKeys.success_title),
          message: t(alertKeys?.[preset] ?? preset),
          type: "success",
        }
      default:
        return {
          title: t(alertKeys.error_title),
          message: t(alertKeys?.[preset], {
            _: formDictionary?.[language as FormDictionnaryLang]?.[preset as FormDictionnaryKey] ?? preset,
          }),
          type: "error",
        }
    }
  }

  return (preset?: AlertPresetValues) => {
    triggerAlert(getPreset(preset))
  }
}
