import { ALERT_CODES, type AlertPresetKeys } from "~/lib/alert"

export type Errors = Record<string, AlertPresetKeys>

export default function useCustomerErrors() {
  return (errors: any, formSetError?: any) => {
    const errsFields: Errors = {}
    const errsGlobals: Errors = {}

    const handleErrorMapping = (err: any) => {
      const fieldName = err?.field?.[1]
      if (fieldName && err?.code) {
        errsFields[fieldName] = err.code
      } else if (err?.code) {
        errsGlobals[err.code] = err.code
      } else {
        errsGlobals[ALERT_CODES.SERVER_ERROR] = ALERT_CODES.SERVER_ERROR
      }
    }

    if (errors?.payload) {
      errors.payload?.forEach(handleErrorMapping)
    }

    if (errors?.response) {
      errors?.response?.errors?.forEach((err: any) => {
        handleErrorMapping({
          ...err,
          ...(err?.extensions ?? {}),
        })
      })
    }

    if (errors?.payload || errors?.response) {
      Object.keys(errsFields)?.map((key) => {
        const obj = errsFields[key]

        if (formSetError) {
          formSetError(key, {
            types: obj,
          })
        }
      })
    }

    return {
      errsFields,
      errsGlobals,
    }
  }
}
