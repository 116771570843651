import { z, type ZodObject, type ZodType } from "zod"

export function setDictionarySchema<Key extends string>(keys: Key[]) {
  return z.object(
    keys.reduce(
      (acc, key) => ({
        ...acc,
        [key]: z.string().min(1).catch(key),
      }),
      {} as Record<Key, ZodType>
    )
  ) as ZodObject<Record<Key, ZodType>>
}
