import type { UnlikelyOrderFulfillmentStatus } from "@unlikelystudio/commerce-connector"

export const fulFillmentStatus = [
  "FULFILLED",
  "OPEN",
  "IN_PROGRESS",
  "PARTIALLY_FULFILLED",
  "ON_HOLD",
  "PENDING_FULFILLMENT",
  "RESTOCKED",
  "UNFULFILLED",
  "SCHEDULED",
] satisfies UnlikelyOrderFulfillmentStatus[]
